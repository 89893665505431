// FileItem.js
import React, { useRef, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import {useModal} from '../../components/common/Modal/ModalContext'
import renameSvg from '../../assets/img/icon/rename.svg'
import moveSvg from '../../assets/img/icon/move.svg'
import sharePeopleSvg from '../../assets/img/icon/share-people.svg'
import shareSvg from '../../assets/img/icon/share.svg'
import deleteSvg from '../../assets/img/icon/delete.svg'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../components/common/LanguageProvider';

function FileItem({ file, index, onDelete, onRename, onMove, onShareTeam, onShare, folders, teams, isChecked, onCheckboxChange, isFile, onProcessing, isFolder, onCreateFolder, onCreateWorkspace, onProcessingFileClicked }) {
    
    const { t } = useTranslation();
    const uid = file?.PK?.S.replace('User#', "");
    const tid = file?.SK?.S.replace('#Recorditor#', '');
    const name = file?.TempName?.S;
    const orderid = file?.OrderID?.S;
    const navigate = useNavigate();

    var date_flag = false;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    try {
        var myDate;
        if (file?.Date?.N) {
            myDate = new Date(parseInt(file.Date.N)); // assuming file.Date.N is a Unix timestamp in milliseconds
        } else {
            myDate = new Date(); // using current date
        }
        var currentDate = Date.now();
        var diffInMs = currentDate - myDate.getTime();                
        if (diffInMs < (1000 * 60 * 60 * 24)) {
          date_flag = true;
        }
      }  catch (error) {
        console.error("Error while parsing and comparing dates:", error);
    }
    var status = file?.TStatus?.S;
    if(status == "Processing" && !date_flag){
        status = "";
    }

    const handleNavigate = () => {
        // Condition to decide whether to navigate or show popup
        if (status == "Processing") {
            onProcessingFileClicked(tid);
        } else {
          navigate(`/recording/${tid}`);
        }
      };


    var myDate = new Date(parseInt(file?.Date?.N)); // assuming r.Date.N is a Unix timestamp in milliseconds

    let timer = myDate.toLocaleString();
    let timer2 = myDate.toLocaleDateString();
    const { showModal } = useModal();

    return (
        <tr>
            <th style={{ display: !isFile || isFolder  ? "none" : "" }} className="web-view">
                <div className='checkbox custom d-inline-block'>
                    <label>
                        <input type="checkbox" checked={isChecked} onChange={(e) => onCheckboxChange(file?.SK?.S, e.target.checked)}/>
                        {index + 1}
                    </label>
                </div>
            </th>
          
            <td class="pointer" onClick={handleNavigate}>

                <span class="dark-color regular filename" >{name}</span>
              

                </td>
         
            <td class="res-hide-td text-end" onClick={handleNavigate} style={{  cursor: 'pointer' }}>

              <a> {(isFile || isFolder) ? timer : timer2} </a>

                </td>
                <td className="res-hide-td text-end w-10">
                 
                        <span className="status processing"  style={{ display: status === "Processing" ? "" : "none" }}>
                            <div className="spinner"></div>
                            {t("Processing")}
                        </span>
                        <span className="status failed"  style={{ display: status === "Failed" ? "" : "none" }}>
                            {t("Failed")}
                        </span>
               
                </td>
             
           
            <td className='text-end'>
                <div className='action-icon-list res-action-list'>
                    <Link onClick={status !== "Processing" ? () => showModal('RENAME', { name: name, tid: tid, uid: uid }, { onRename }):null}  style={{ opacity: status === "Processing" ? "0.5" : "" }}>
                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Rename")}>
                            <img src={renameSvg} className="img-fluid" alt="img"/>
                        </span>
                    </Link>
                    <Link onClick={status !== "Processing" ? () => showModal('MOVE', {  folders: folders, uid: uid, tid: tid }, { onMove, onCreateFolder: onCreateFolder  }):null} style={{ opacity: status === "Processing" ? "0.5" : "" }}>
                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Move")}>
                            <img src={moveSvg} className="img-fluid" alt="img"/>
                        </span>
                    </Link>
                    <Link onClick={status !== "Processing" ? () => showModal('SHARE-TEAM', { teams: teams, tid: tid, uid: uid }, { onShareTeam, onCreateWorkspace }):null} style={{ opacity: status === "Processing" ? "0.5" : "" }}>
                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Add to a workspace")}>
                            <img src={sharePeopleSvg} className="img-fluid" alt="img"/>
                        </span>
                    </Link>
                    <Link onClick={() => onShare(orderid)}>
                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Share")}>
                            <img src={shareSvg} className="img-fluid" alt="img"/>
                        </span>
                    </Link>
                    <Link     onClick={status !== "Processing" ? () => showModal('DELETE', { tid: tid, uid: uid }, { onDelete }) : null}  style={{ opacity: status === "Processing" ? "0.5" : "" }}>
                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Delete")}>
                            <img src={deleteSvg} className="img-fluid" alt="img"/>
                        </span>
                    </Link>
                </div>
                <div class="dropdown dropstart res-action-dropdown" ref={dropdownRef}>
					<button className={`btn dropdown-toggle`} type="button" id="dropdownMenuButton1"   data-bs-toggle="dropdown">
						<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
							<path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
						</svg>
					</button>
                    <ul class="dropdown-menu "aria-labelledby="dropdownMenuButton1" >
						<li><Link onClick={status !== "Processing" ? () => showModal('RENAME', { name: name, tid: tid, uid: uid }, { onRename }):null} class="dropdown-item" style={{ opacity: status === "Processing" ? "0.5" : "" }}>
							<span class="action-drop-icon">
							    <img src={renameSvg}class="img-fluid" alt="img"/>
							</span>{t("Rename")}
						</Link></li>
									      			<li><Link onClick={status !== "Processing" ? () => showModal('MOVE', {  folders: folders, uid: uid, tid: tid }, { onMove, onCreateFolder: onCreateFolder  }):null} class="dropdown-item" style={{ opacity: status === "Processing" ? "0.5" : "" }}>
									      				<span class="action-drop-icon">
									      					<img src={moveSvg} class="img-fluid" alt="img"/>
									      				</span>{t("Move")}
									      			</Link></li>
									      			<li><Link onClick={status !== "Processing" ? () =>  showModal('SHARE-TEAM', { teams: teams, tid: tid, uid: uid }, { onShareTeam, onCreateWorkspace }):null} class="dropdown-item" style={{ opacity: status === "Processing" ? "0.5" : "" }}>
									      				<span class="action-drop-icon">
									      					<img src={sharePeopleSvg} class="img-fluid" alt="img"/>
									      				</span>{t("Add to a workspace")}
									      			</Link></li>
									      			<li><Link onClick={() => onShare(orderid)} class="dropdown-item share_btn">
									      				<span class="action-drop-icon">
									      					<img src={shareSvg} class="img-fluid" alt="img"/>
									      				</span>{t("Share")}
									      			</Link></li>
									      			<li><Link onClick={status !== "Processing" ? () => showModal('DELETE', { tid: tid, uid: uid }, { onDelete }):null} class="dropdown-item"style={{ opacity: status === "Processing" ? "0.5" : "" }}>
									      				<span class="action-drop-icon">
									      					<img src={deleteSvg} class="img-fluid" alt="img"/>
									      				</span>{t("Delete")}
									      			</Link></li>
											  	</ul>
											</div>
            </td>
         
        </tr>
    );
}

export default FileItem;
