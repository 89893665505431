import React, { useRef, useEffect, useState } from 'react';
import { useParams, Link, useLocation } from "react-router-dom";

import '../assets/css/bootstrap.min.css';
import '../assets/css/font-awesome.css';
import '../assets/css/custome.css';
import '../assets/css/responsive.css';

import { useAuth } from '../Auth/AuthContext';
import { useNavigate } from 'react-router-dom';

import rename from "../assets/img/icon/rename.svg";
import fileMark from '../assets/img/files/file-mark.svg';
import undo from '../assets/img/files/undo.svg';
import redo from '../assets/img/files/redo.svg';
import share from '../assets/img/files/share.svg';
import tabTranskriptorBlue from '../assets/img/files/tab-transkriptor-blue.png';
import tabTranskriptorWhite from '../assets/img/files/tab-transkriptor-white.png';
import tabRecorditorRed from '../assets/img/files/tab_recorditor_red.jpg';
import tabRecorditorWhite from '../assets/img/files/tab_recorditor_white.jpg';
import { fetchData, fetchDataPost } from "../hooks/apiUtils";
import { showToast } from "../components/common/toast";
import { useModal } from "../components/common/Modal/ModalContext";
import logoTranskriptor from '../assets/img/logo-transkriptor-nobuffer.png';
import recorditorLogo from "../assets/img/logo_nobuffer.png"


import logoRecorditor from '../assets/img/logo_nobuffer_white.png';
import ReactPlayer from 'react-player';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from '../components/common/LanguageProvider';


function RecordingView({ handleOpenSidebar }) {
    const { t } = useTranslation();
	const { oid } = useParams();
	const { userData, hashedId } = useAuth();
	const [userStatus, setUserStatus] = useState(null);
	const [orderData, setOrderData] = useState(null);
	const [transcriptionInProgress, setTranscriptionInProgress] = useState(false);
	const transcriptionOrderId = useRef(null);
	const [duration, setDuration] = useState(null);
	const [date, setDate] = useState(null);
	const [recordingName, setRecordingName] = useState(null);
	const videoRef = useRef(null);
	const transcriptionStatus = useRef(null);

	const [videoLoading, setVideoLoading] = useState(true);

	var retryCount = useRef(0);

	const [videoUrl, setVideoUrl] = useState(orderData?.video_url);


	const { showModal } = useModal();
	const [showProcessingModal, setShowProcessingModal] = useState(false);
	const [processingTranscriptionLoading, setProcessingTranscriptionLoading] = useState(true);

	const [reduceSize, setReduceSize] = useState(false);

	const undoRef = useRef(null);
	const redoRef = useRef(null);

	useEffect(() => {
		shareLink();
	}, []);


	useEffect(() => { getOrderInfo(true); }, [
		oid
	]);
	useEffect(() => {
		if (userData)
			setUserStatus(userData.SStatus);
	}, [userData]);

	const handleUndo = (e) => {
		console.log("undo");
		e.preventDefault();
		document.execCommand('undo', false, null);
	}

	const handleError = (e) => {
		console.log(e);

		if (retryCount.current < 30) {
			retryCount.current++;
			setTimeout(() => {

				setVideoUrl(orderData?.video_url + `?attempt=${retryCount.current}`);
			}, 3000); // 3 seconds delay
		} else {
			showToast("Error loading video", { type: "error" });
			setVideoLoading(false);
			retryCount.current = 0;
		}

	};





	function convertMsToFormattedDate(ms) {
		ms = parseInt(ms);
		const date = new Date(ms);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		const seconds = String(date.getSeconds()).padStart(2, '0');

		return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
	}

	const isMobileDevice = () => {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	};


	const getTranscriptionStatus = async () => {
		try {
			const response = await fetch('https://p8464z3n1m.execute-api.eu-central-1.amazonaws.com/default/Recorditor-GetOrderContent?oid=' + transcriptionOrderId.current + "&getTranscription=1");
			if (response.ok) {
				const data = await response.json();
				transcriptionStatus.current = data?.recording?.TStatus;
			}
			return true;
		} catch (error) {
			console.log(error);
		}
	};
	const onRename = async (tid, uid, name) => {
		setRecordingName(name);
		const response = await fetchDataPost(
			"https://v91m7qwz82.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFile?",
			{ tid: "#Recorditor#" + tid, Tname: name, cid: "User#" + uid }
		);
		if (response)
			showToast(t("File renamed"), { type: "success" });
	};

	const shareLink = async () => {
		try {
			// Get the current origin (e.g., "http://localhost:3000" or "https://app.transkriptor.com")
			let baseUrl = window.location.origin;

			let link = baseUrl + "/recording/" + oid;

			await navigator.clipboard.writeText(link);

			showToast(t("File link copied to clipboard"));

			return true;

		} catch (err) {
			console.error('Failed to copy text: ', err);
			return false;
		}
	};


	const getOrderInfo = async () => {
		try {
			const res = await fetch(`https://p8464z3n1m.execute-api.eu-central-1.amazonaws.com/default/Recorditor-GetOrderContent?oid=${oid}`);
			if (res.ok) {
				const result = await res.json();
				setOrderData(result?.recording);
				setVideoUrl(result?.recording?.video_url + `?attempt=${retryCount.current}`);
				if (result?.recording?.TranskriptorOrderId && result?.recording?.TranskriptorOrderId !== "Authorization Error") {
					transcriptionOrderId.current = result?.recording?.TranskriptorOrderId;
					await getTranscriptionStatus();
				}
				setRecordingName(result?.recording?.TempName);
				setDate(convertMsToFormattedDate(result?.recording?.Date));
				setTranscriptionInProgress(false);
				return true;
			}
		}
		catch (error) {
			console.log(error);
		}
	};

	const initTranscriptModal = async (orderData) => {


		if (transcriptionOrderId.current) {
			if (transcriptionStatus.current) {
				if (transcriptionStatus.current !== "Completed" && transcriptionStatus.current !== "Failed") {
					onTranscriptionClickedWhileTranscriptionInProgress();
					return;
				}
			} else {
				return;
			}


			if (isMobileDevice()) {
				startTranscriptionAttempt(orderData);
				window.location.href = "https://transkriptormobile.page.link/sign";
			} else {
				const sk = await startTranscriptionAttempt(orderData);
				if (sk) {
					const url = `https://app.transkriptor.com/editor/${sk}/${hashedId}?uid=${hashedId}`
					window.open(url, "_self");
				}
			}

		} else {
			if (userStatus && userStatus === "Trial") {
				showModal("UPGRADE-TRANSCRIBE");
			} else {
				showModal("TRANSCRIPTION-LANGUAGE-MODAL", { startAndNavigateTranscription, orderData });
			}
		}


	};



	const startAndNavigateTranscription = async (orderData) => {
		if (isMobileDevice()) {

			startTranscriptionAttempt(orderData);
			window.location.href = "https://transkriptormobile.page.link/sign";

		} else {
			const sk = await startTranscriptionAttempt(orderData);
			if (sk) {
				onTranscriptionClickedWhileTranscriptionInProgress();
				//const url = `https://app.transkriptor.com/editor/${sk}/${hashedId}?uid=${hashedId}`
				//window.open(url, '_blank');
			}
		}
	};

	const handleLoadedMetadata = () => {
		const player = videoRef.current;
		if (!player) return;
		retryCount.current = 0;
		const duration = player.getDuration();
		const aspectRatio = player.getInternalPlayer().videoWidth / player.getInternalPlayer().videoHeight;
		if (aspectRatio < 1.4) {
			setReduceSize(true);
		}

		setVideoLoading(false);
		setDuration(duration);
	};

	const onTranscriptionClickedWhileTranscriptionInProgress = async () => {

		setShowProcessingModal(true);
		setProcessingTranscriptionLoading(true);
		const res = await getTranscriptionStatus();
		if (res) {
			if (transcriptionStatus.current !== "Completed" && transcriptionStatus.current !== "Failed") {
				setProcessingTranscriptionLoading(false);
			} else {
				setProcessingTranscriptionLoading(false);
				setShowProcessingModal(false);
				initTranscriptModal(orderData);
			}
		} else {
			setShowProcessingModal(false);
		}

	}

	useEffect(() => {
		const handleCopy = (e) => {
			if (userStatus && userStatus === "Trial") {
				e.preventDefault();
				showModal("UPGRADE-DOWNLOAD");
			}
		};

		document.addEventListener('copy', handleCopy);

		return () => {
			document.removeEventListener('copy', handleCopy); // Cleanup the event listener on component unmount
		};
	}, [userStatus]);


	const formatTime = (seconds) => {
		const hours = Math.floor(seconds / 3600);
		seconds %= 3600;
		const min = Math.floor(seconds / 60);
		const sec = Math.floor(seconds % 60);

		if (hours > 0) {
			return `${hours}:${min.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
		} else {
			return `${min}:${sec.toString().padStart(2, "0")}`;
		}
	};


	const startTranscriptionAttempt = async (orderData) => {
		let sk = null;
		try {
			setTranscriptionInProgress(true);
			const response = await fetch('https://tjuok9i47g.execute-api.eu-central-1.amazonaws.com/default/Recorditor-InitiateTranscription', {
				method: 'POST',
				body: JSON.stringify({ "tid": orderData?.SK ?? "", "cid": orderData?.PK ?? "" })
			});
			if (!response.ok) {
				if (response.status === 406) {
					showModal("UPGRADE-TRANSCRIBE");
				} else {
					const data = await response.json();
					showToast(data.message, { type: "error" });
				}
			} else {
				const data = await response.json();

				sk = data.sk.replace("#Transkription#", "");
				getOrderInfo();
			}

		} catch (error) {
		}
		setTranscriptionInProgress(false);
		return sk;
	}

	const checkUser = async () => {
		const response = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId);
		if (response.SStatus === "Trial") {
			showModal("UPGRADE-DOWNLOAD");
		} else {
			// Create a new anchor element
			const link = document.createElement('a');
			link.href = orderData?.video_url;
			link.setAttribute(
				'download',
				recordingName + '.mp4',
			);

			// Append to the DOM
			document.body.appendChild(link);

			// Programmatically click the link to trigger the download
			link.click();

			// Remove the link from the DOM
			document.body.removeChild(link);
		}
	}

	return (
		<div class="container-fluid p-0">
			<Modal show={showProcessingModal} onHide={() => setShowProcessingModal(false)} centered>
				<Modal.Body >
					<button type="button" class="btn-close" onClick={() => setShowProcessingModal(false)} aria-label="Close"></button>
					<div class="text-center">
						<h2 class="mini-title text-center mb-4">{t("Transcription in progress")}</h2>
						<p class="ft-18-title mb-4">{t("This recording's transcription is in progress and the process can take as long as the length of the recording. You can close this page if you wish, and you will receive a notification by email when your transcription is ready.")}</p>
					</div>
					<div class="text-center">
						{processingTranscriptionLoading ? (
							<div className="spinner-border text-primary" role="status"> </div>
						) : (
							<></>)}
					</div>
				</Modal.Body>
			</Modal>
			<div class="row justify-content-center">
				<>
					<div className="container-fluid p-0">
						<div className="row justify-content-center">

							<div className="col-12">
								<div className="card card-padding overflow-visible">
									<div className="row align-items-center">
										<div className="col-xxl-4 col-lg-4 col-md-4 col-12 mb-2 order-xxl-1 order-lg-1 order-md-1 order-1 text-md-start text-center notranslate">
											<h2 class="small-title regular mb-2">
												<span class="me-2 burger-web-view">
													<img
														src={fileMark}
														class="img-fluid"
														height="auto"
														width="28px"
														alt="img"
													/>
												</span>
												{!isMobileDevice() &&
													<span class="me-2 burger-mobile-view" onClick={() => handleOpenSidebar()}>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-black" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 12H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 6H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 18H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>

													</span>}
												<div className="filename-container">
													<span className="filename">{recordingName}</span>
													<Link
														onClick={() =>
															showModal(
																"RENAME",
																{ name: recordingName, tid: oid, uid: hashedId },
																{ onRename }
															)
														}
														className="web-view"
													>
														<span
															class="icons-hover"
															data-bs-toggle="tooltip"
															data-bs-placement="bottom"
															title={t("Rename")}
															data-bs-original-title="Rename"
														>
															<img src={rename} class="img-fluid" alt="img" />
														</span>
													</Link>
												</div>
											</h2>
											<p class="d-sm-inline-block d-block ft-14 mb-1 me-sm-4 me-0 web-view">
												<span class="me-1">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="14"
														height="16"
														viewBox="0 0 19.2 18"
													>
														<g id="date-line" transform="translate(-2 -2)">
															<path
																id="Path_135"
																data-name="Path 135"
																d="M20.15,6H18.2V7.2H20V20.4H3.2V7.2H5V6H3.05A1.068,1.068,0,0,0,2,7.086V20.514A1.068,1.068,0,0,0,3.05,21.6h17.1a1.068,1.068,0,0,0,1.05-1.086V7.086A1.068,1.068,0,0,0,20.15,6Z"
																transform="translate(0 -1.6)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_136"
																data-name="Path 136"
																d="M8,14H9.2v1.2H8Z"
																transform="translate(-2.4 -4.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_137"
																data-name="Path 137"
																d="M14,14h1.2v1.2H14Z"
																transform="translate(-4.8 -4.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_138"
																data-name="Path 138"
																d="M20,14h1.2v1.2H20Z"
																transform="translate(-7.2 -4.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_139"
																data-name="Path 139"
																d="M26,14h1.2v1.2H26Z"
																transform="translate(-9.6 -4.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_140"
																data-name="Path 140"
																d="M8,19H9.2v1.2H8Z"
																transform="translate(-2.4 -6.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_141"
																data-name="Path 141"
																d="M14,19h1.2v1.2H14Z"
																transform="translate(-4.8 -6.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_142"
																data-name="Path 142"
																d="M20,19h1.2v1.2H20Z"
																transform="translate(-7.2 -6.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_143"
																data-name="Path 143"
																d="M26,19h1.2v1.2H26Z"
																transform="translate(-9.6 -6.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_144"
																data-name="Path 144"
																d="M8,24H9.2v1.2H8Z"
																transform="translate(-2.4 -8.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_145"
																data-name="Path 145"
																d="M14,24h1.2v1.2H14Z"
																transform="translate(-4.8 -8.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_146"
																data-name="Path 146"
																d="M20,24h1.2v1.2H20Z"
																transform="translate(-7.2 -8.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_147"
																data-name="Path 147"
																d="M26,24h1.2v1.2H26Z"
																transform="translate(-9.6 -8.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_148"
																data-name="Path 148"
																d="M9.6,6.8a.6.6,0,0,0,.6-.6V2.6A.6.6,0,0,0,9,2.6V6.2A.6.6,0,0,0,9.6,6.8Z"
																transform="translate(-2.8)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_149"
																data-name="Path 149"
																d="M25.6,6.8a.6.6,0,0,0,.6-.6V2.6a.6.6,0,0,0-1.2,0V6.2A.6.6,0,0,0,25.6,6.8Z"
																transform="translate(-9.2)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_150"
																data-name="Path 150"
																d="M13,6h6V7.2H13Z"
																transform="translate(-4.4 -1.6)"
																fill="#6b6c6d"
															/>
														</g>
													</svg>
												</span>
												{date}
											</p>
											<p class="d-sm-inline-block d-block ft-14 mb-1 web-view">
												<span class="me-1">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="14"
														height="14"
														viewBox="0 0 16.875 16.875"
													>
														<g id="clock" transform="translate(-1.125 -1.125)">
															<path
																id="Path_151"
																data-name="Path 151"
																d="M17.966,6.715H16.841v6.261l4.064,1.932.483-1.016-3.423-1.627Z"
																transform="translate(-7.858 -2.795)"
																fill="#6b6c6d"
															/>
															<path
																id="Path_152"
																data-name="Path 152"
																d="M9.563,1.125A8.438,8.438,0,1,0,18,9.563,8.437,8.437,0,0,0,9.563,1.125Zm0,15.75a7.313,7.313,0,1,1,7.313-7.312A7.313,7.313,0,0,1,9.563,16.875Z"
																fill="#6b6c6d"
															/>
														</g>
													</svg>
												</span>
												{formatTime(duration)}
											</p>
										</div>
										<div class="col-xxl-5 col-lg-4 col-md-4 col-12 order-xxl-2 order-lg-2 order-md-3 order-3">
										</div>
										<div class="col-xxl-3 col-lg-4 col-md-4 col-12 order-xxl-3 order-lg-3 order-md-2 order-2 text-md-end text-center notranslate">
											<div class="file-action-icon-flex mb-3">
												<Link ref={undoRef} onClick={handleUndo} style={{ display: "none" }} className="hover-effect">
													<span
														class="file-action-list"
														data-bs-toggle="tooltip"
														data-bs-placement="bottom"
														title={t("Undo")}
													>
														<img src={undo} class="img-fluid" alt="img" />
													</span>
												</Link>
												<Link ref={redoRef} style={{ display: "none" }} className="hover-effect">
													<span
														class="file-action-list"
														data-bs-toggle="tooltip"
														data-bs-placement="bottom"
														title={t("Redo")}
														ref={redoRef}
													>
														<img src={redo} class="img-fluid" alt="img" />
													</span>
												</Link>
												<Link
													onClick={(e) => {
														e.preventDefault();
														checkUser()
													}}
													className="hover-effect"
												>
													<span
														class="file-action-list theme-color"

													>
														<span className="hover-span">
															{t("Download")}
														</span>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="21"
															height="19.34"
															viewBox="0 0 21 19.34"
														>
															<g
																id="Group_46"
																data-name="Group 46"
																transform="translate(-0.16)"
															>
																<rect
																	id="Rectangle_36"
																	data-name="Rectangle 36"
																	width="21"
																	height="15"
																	rx="3"
																	transform="translate(21.16 15.34) rotate(180)"
																	fill="#fff"
																/>
																<rect
																	id="Rectangle_37"
																	data-name="Rectangle 37"
																	width="21"
																	height="5"
																	rx="2.5"
																	transform="translate(21.16 17.34) rotate(180)"
																	fill="currentColor"
																/>
																<rect
																	id="Rectangle_38"
																	data-name="Rectangle 38"
																	width="21"
																	height="5"
																	rx="2.5"
																	transform="translate(21.16 19.34) rotate(180)"
																	fill="#fff"
																/>
																<g
																	id="arrow-small-down"
																	transform="translate(5.402)"
																>
																	<path
																		id="Path_163"
																		data-name="Path 163"
																		d="M21.767,18.641l-4.612,4.613H15.862L11.25,18.641l1.292-1.31,3.044,3.026V15H17.43v5.357l3.045-3.045Z"
																		transform="translate(-11.25 -15)"
																		fill="currentColor"
																	/>
																</g>
															</g>
														</svg>

													</span>
												</Link>
												<Link class="share_btn hover-effect" onClick={(e) => {
													e.preventDefault();
													shareLink()
												}}>
													<span
														class="file-action-list"
													>
														<span className="hover-span">
															{t("Share")}
														</span>
														<img src={share} class="img-fluid" alt="img" />
													</span>
												</Link>
											</div>
										</div>
									</div>
									<hr class="mt-0" />
									<div >
										<ul
											class="nav nav-pills btn-pills mb-3 notranslate"
											id="pills-tab"
											role="tablist"
										>
											<li class="nav-item" role="presentation">
												<button
													class="nav-link active"
													id="pills-traskript-tab"
													data-bs-toggle="pill"
													data-bs-target="#pills-traskript"
													type="button"
													role="tab"
													aria-controls="pills-traskript"
													aria-selected="true"
												>
													<span class="btn-tab-icon" >
														<img
															src=''
															class="img-fluid btn-reg-icon"
															alt="img"
														/>
														<img
															src={logoRecorditor}
															class="img-fluid btn-active-icon"
															alt="img"
														/>
													</span>
												</button>
											</li>
											<OverlayTrigger
												delay={{ hide: 450, show: 300 }}

												overlay={(props) => (
													<Tooltip {...props}>
														{t("Transcribe this recording with Transkriptor")}
													</Tooltip>
												)}
												placement="bottom"
											>


												<button
													class="nav-link "
													onClick={() => { initTranscriptModal(orderData) }}
													style={{ borderColor: "#007bff", backgroundColor: "white" }}
												>
													<span class="btn-tab-icon" >
														<img
															src={logoTranskriptor}
															class="img-fluid btn-reg-icon"
															alt="img"
														/>
														<img
															src={tabTranskriptorWhite}
															class="img-fluid btn-active-icon"
															alt="img"
														/>
													</span>
												</button>
											</OverlayTrigger>
										</ul>
										<div class="tab-content" id="pills-tabContent">
											<div
												class="tab-pane fade show active"
												id="pills-traskript"
												role="tabpanel"
												aria-labelledby="pills-traskript-tab"
											>
												{(videoLoading || transcriptionInProgress) &&
													<div className=' card-padding'>
														<br />
														<div className='d-flex justify-content-center text-center'>
															<span class="spinner-border text-primary"></span>
														</div>
													</div>
												}
												<div class="row gx-0 w-100 m-0">
													<div className="text-center" >

														<div className="video-container position-relative" style={{ width: reduceSize ? '40%' : '60%', margin: '0 auto' }}>
															{orderData?.only_audio == 'true' && !isMobileDevice() &&
																<div className="icon-overlay" style={{
																	position: 'absolute',
																	top: '50%',
																	left: '50%',
																	transform: 'translate(-50%, -50%)',
																	zIndex: 2,
																}}>
																	<img src={recorditorLogo} width="70%"></img>
																</div>}

															<ReactPlayer
																url={videoUrl}
																playing={true}
																volume={1}
																width="100%"
																height="100%"

																controls
																playsinline
																className='mb-3' id='video-src' style={{ display: videoLoading ? 'none' : null, width: '60%' }}
																onReady={handleLoadedMetadata} ref={videoRef}
																onError={handleError}
																onContextMenu={(e) => e.preventDefault()}
																config={{
																	attributes: {
																		disablePictureInPicture: true,
																		controlsList: 'nodownload noplaybackrate'
																	},
																	file: {
																		forceVideo: true
																	}
																}}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			</div>
		</div>
	);
}

export default RecordingView;