import React, {useEffect, useState, useRef} from 'react';
import { useAuth } from '../Auth/AuthContext';
import upgrade from '../assets/img/icon/upgrade.svg';
import folders from '../assets/img/icon/folders.svg';
import { useNavigate, useLocation } from "react-router-dom";
import Files from './Files/Files';
import { useModal } from '../components/common/Modal/ModalContext';
import FileGallery from './FileGallery/FileGallery';
import PromptGenerator from './ImageGenerator/PromptBox';
import StyleBox from './ImageGenerator/StyleBox';
import AspectRatioSelector from './ImageGenerator/AspectRatioBox';
import { useTranslation } from '../components/common/LanguageProvider';
function Dashboard({isBlocked  ,setIsBlocked}) {
  const { t } = useTranslation();
  const { planInfo, userData } = useAuth();
  const [credits, setCredits] = useState(null);
  
  const navigate = useNavigate();
  const {showModal} = useModal();

  useEffect(() => {
		if (planInfo) {
			setCredits(planInfo.allowed - planInfo.total_orders);
		}
	}, [planInfo]);
  useEffect(() => {
    if(!localStorage.getItem("transcript-language"))
        findLanguage();
    if(!localStorage.getItem("isLanguageSet")){
        localStorage.setItem("isLanguageSet", true);
        showModal("LANGUAGE-MODAL");

    }
}, []);
const findLanguage = async () => {
  try {
    const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
    const [iso639_1, countryCode] = browserLanguage.split("-");

    localStorage.setItem("dashboard-language", iso639_1);
    localStorage.setItem("dashboard-country", countryCode || 'US');
    localStorage.setItem("transcript-language", browserLanguage);
    window.location.reload();

  } catch (error) {
    console.error('Error detecting browser language:', error);
  }
};

    return (
       <>
              <div className='row justify-content-center'>        
              <div className='col-lg-4 card-margin'>
                  <div className='card card-padding card-100 '>
                   <PromptGenerator/>
                  </div>
                </div>
                <div class="col-lg-4 card-margin ">
                      <div class="card card-padding card-100  " >
                        <StyleBox/>
                        
                      </div>

                  </div>
                  <div class="col-lg-4 card-margin d-lg-block d-none">
                      <div class="card card-padding card-100  " >
                        <AspectRatioSelector/>
                        
                      </div>

                  </div>
                   
           
                <div className='col-lg-8 card-margin'>
                  <div className='card card-padding card-100 '>
                  <FileGallery isFile={false}/>
                  </div>
                </div>
                <div class="col-lg-4 card-margin d-lg-block d-none">
                  <div class="row h-100">
                    <div class="col-12 mb-3">
                      <div class="card card-padding card-100 flex-center card-hover" onClick={()=> navigate("/payment")}>
                        <div class="w-100 d-sm-flex d-block align-items-center justify-content-between">
                          <img src={upgrade} class="img-fluid order-2 mb-sm-0 mb-3" alt="img"/>
                          <div class="me-3 order-1">
                            <p class="ft-18-title mb-2">{t("Upgrade")}</p>
                            <p class="mb-0">{t("Upgrade your account to record more videos and get the transcription of videos")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 ">
                      <div class="card card-padding card-100 flex-center card-hover"  onClick={()=> navigate("/folders")}>
                        <div class="w-100 d-sm-flex d-block align-items-center justify-content-between">
                          <img src={folders} class="img-fluid order-2 mb-sm-0 mb-3" alt="img"/>
                          <div class="me-3 order-1">
                            <p class="ft-18-title mb-2">{t("Folders")}</p>
                            <p class="mb-0">{t("Create folders and arrange your files as your needs.")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
				        </div>
           
              </div>
       </>
    );
}

export default Dashboard;



