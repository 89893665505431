import React, {useEffect} from 'react';
import { useAuth } from '../Auth/AuthContext';
import audioIcon from '../assets/img/icon/audio-icon.svg';
import googleDriveIcon from '../assets/img/icon/google-drive.svg';
import youtubeIcon from '../assets/img/icon/youtube.svg';
import zoom from '../assets/img/icon/zoom.svg';
import transkriptorLogo from "../assets/img/logo_nobuffer.png"
import eskritorLogo from "../assets/img/logo-eskritor-nobuffer.png"
import speaktorLogo from "../assets/img/logo-speaktor-nobuffer.png"

import recordPng from "../assets/img/icon/record-btn-icon.svg"

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from '../components/common/LanguageProvider';
function ReceivedPayment() {


  const { t } = useTranslation();

  const navigate = useNavigate();


  
    setTimeout(()=>{
      navigate("dashboard")
    }, 10000)
    return (
       <>

              <div className='row justify-content-center'>
                <div className='col-lg-10 card-margin'>
                  <div className='card card-padding card-100 text-center card-hover' >
                    <h2>{t("Thank you for your purchase!")}</h2>
                    <p >{t("Please allow 10-20 seconds to see your minutes in your account.")}</p>
                    <div className='img-icon-list' style={{display: "none"}}>
                        <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Audio")}><img src={audioIcon} class="img-fluid" alt="img"/></span>
                        <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Youtube"><img src={youtubeIcon} class="img-fluid" alt="img"/></span>
                        <span class="pointer red-bg recorder-span2 d-flex align-items-center justify-content-center" style={{borderRadius: "10px"}} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Youtube"><img src={recordPng} class="img-fluid" alt="img"/></span>

                        <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Zoom"><img src={zoom} class="img-fluid" alt="img"/></span>

                    </div>
                    <img src={transkriptorLogo} width="20%"></img>
					<p>{t("Convert Any Audio Or Video File To Text From Your Computer, Cloud Storage, Youtube, Record Screen or Record Meetings (Zoom, Google Meet, Teams)")}</p>
					<img src={speaktorLogo} width="18%"></img>
					<p>{t("Convert Any Text File To Audio From Your Computer (docs, word, txt), website URL, or just copy from clipboard")}</p>
					<img src={eskritorLogo} width="16%"></img>
					<p>{t("Automatically generate different formats of text with Eskritor’s artificial intelligence (AI) content writer")}</p>
					
                  </div>
                </div>

           
              </div>
       </>
    );
}

export default ReceivedPayment;



