import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import './generator.css';
import { useTranslation } from '../../components/common/LanguageProvider';


function StyleBox({
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const filesLength = 12;
    const [currentPage, setCurrentPage] = useState(1);
    const filesPerPage = 3;
    const [filesToShow, setFilesToShow] = useState([]);
    var startIndex = 0;
    var endIndex = 0;
    const [selectedId, setSelectedId] = useState(0);
    const [offset, setOffset] = useState(0);
    const [styles, setStyles] = useState([
        { id: 0, label: 'No Style', imageUrl: 'https://static.thenounproject.com/png/4110381-200.png' },
        { id: 1, label: 'Surrealistic', imageUrl: 'https://slp-statics.astockcdn.net/static_assets/staging/23fall/generate/mosaic/card_5_2.jpg?width=580&format=webp' },
        { id: 2, label: 'Midjourney', imageUrl: 'https://impossibleimages.ai/wp-content/uploads/55JgrNQGqBieaFvnKJZBnMrdbi9uqm10UojZaDczSVcAF1EcbM-600x600.jpg' },
        { id: 3, label: 'Van Gogh', imageUrl: 'https://impossibleimages.ai/wp-content/uploads/tN5adPkY5nb3qZx4CpUK7jb0iZucVrYXgh7lwWp8aI1b4CBH9p-600x600.jpg' },
        { id: 4, label: 'B-W Tattoo', imageUrl: 'https://impossibleimages.ai/wp-content/uploads/Z68GsbruxwqnWE3bAFdJLfQf10H0vU5Itu4Fe3bsAeWpRLy0FP-600x796.jpg' },
        { id: 5, label: 'Portrait', imageUrl: 'https://cdn.impossibleimages.ai/wp-content/uploads/2023/08/29130431/N1RvAPSt6aupQzqI7OncI0uF6FMWTCAdGBsQLWQklHwFXT9MuX-600x600.jpg' },
        { id: 6, label: 'No Style', imageUrl: 'https://slp-statics.astockcdn.net/static_assets/staging/23fall/generate/mosaic/card_1.jpg?width=580&format=webp' },
        { id: 7, label: 'Surrealistic', imageUrl: 'https://slp-statics.astockcdn.net/static_assets/staging/23fall/generate/mosaic/card_5_2.jpg?width=580&format=webp' },
        { id: 8, label: 'Midjourney', imageUrl: 'https://impossibleimages.ai/wp-content/uploads/55JgrNQGqBieaFvnKJZBnMrdbi9uqm10UojZaDczSVcAF1EcbM-600x600.jpg' },
        { id: 9, label: 'Van Gogh', imageUrl: 'https://impossibleimages.ai/wp-content/uploads/tN5adPkY5nb3qZx4CpUK7jb0iZucVrYXgh7lwWp8aI1b4CBH9p-600x600.jpg' },
        { id: 10, label: 'B-W Tattoo', imageUrl: 'https://impossibleimages.ai/wp-content/uploads/Z68GsbruxwqnWE3bAFdJLfQf10H0vU5Itu4Fe3bsAeWpRLy0FP-600x796.jpg' },
        { id: 11, label: 'Portrait', imageUrl: 'https://cdn.impossibleimages.ai/wp-content/uploads/2023/08/29130431/N1RvAPSt6aupQzqI7OncI0uF6FMWTCAdGBsQLWQklHwFXT9MuX-600x600.jpg' }
    ]);

    const [scrollIntervalId, setScrollIntervalId] = useState(null);
    const styleSelectorRef = useRef(null);

    const startScrolling = (direction) => {
        const scrollAmount = direction === 'left' ? -80 : 80;
        const intervalId = setInterval(() => {
            if (styleSelectorRef.current) {
                styleSelectorRef.current.scrollBy(scrollAmount, 0);
            }
        }, 80);
        setScrollIntervalId(intervalId);
    };

    const stopScrolling = () => {
        if (scrollIntervalId) {
            clearInterval(scrollIntervalId);
            setScrollIntervalId(null);
        }
    };


    useEffect(() => {
        // Calculate the indexes of files to be displayed on the current page
        startIndex = (currentPage - 1) * filesPerPage;
        endIndex = startIndex + filesPerPage;

        // Update the files to show based on the new indexes
        setFilesToShow(styles.slice(startIndex, endIndex));

    }, [styles, currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        const newOffset = (newPage - 1) /* Your item width here */; // Calculate new offset based on page number and item width
        setOffset(newOffset);
    };


    return (
        <>
            <label class="ft-18-title mb-3">{t("Styles")}</label>

            <div class="styles-container">
                <button className="scroll-button left" onMouseDown={() => startScrolling('left')} onMouseUp={stopScrolling} onMouseLeave={stopScrolling} onTouchStart={() => startScrolling('left')} onTouchEnd={stopScrolling}>
                    <span className="fa fa-angle-left"></span>
                </button>    <div className="style-selector" ref={styleSelectorRef}>
                    {styles.map((style) => (
                        <div class='style-button' onClick={() => setSelectedId(style.id)}>
                            <img src={style.imageUrl} alt={style.title} className={`style-image ${selectedId === style.id ? 'selected' : ''}`} />
                            <div className="style-label">{style.label}</div>
                        </div>
                    ))}
                </div>
                <button className="scroll-button right" onMouseDown={() => startScrolling('right')} onMouseUp={stopScrolling} onMouseLeave={stopScrolling} onTouchStart={() => startScrolling('right')} onTouchEnd={stopScrolling}>
                    <span className="fa fa-angle-right"></span>
                </button></div> </>
    );

}

export default StyleBox;