import React, { useState } from 'react';
import './generator.css'; 
import { useTranslation } from '../../components/common/LanguageProvider';

const AspectRatioButton = ({ ratio, selected, onSelect }) => {
  const handleClick = () => {
    onSelect(ratio);
  };

  return (
    <button
      className={`aspect-ratio-button ${selected ? 'selected' : ''}`}
      onClick={handleClick}
    >
      {ratio}
    </button>
  );
};

const AspectRatioSelector = () => {
  const { t } = useTranslation();
  const [selectedRatio, setSelectedRatio] = useState('1:1 Square');
  const ratios = [
    { label: '1:1 Square'},
    { label: '4:3 Landscape'},
    { label: '16:9 Landscape' },
    { label: '16:9 Portrait'},
    { label: '4:3 Portrait' },
  ];

  return (
    <div className="aspect-ratio-selector">
    <p class="ft-18-title mb-2">{t("Choose Aspect Ratio")}</p>
      <div className="ratios">
        {ratios.map((ratio) => (
          <AspectRatioButton
            key={ratio.label}
            ratio={ratio.label}
            selected={selectedRatio === ratio.label}
            onSelect={setSelectedRatio}
          />
        ))}
      </div>
    </div>
  );
};

export default AspectRatioSelector;
