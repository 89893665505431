import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../assets/img/rewards/img1.png';
import img2 from '../assets/img/rewards/img2.png';
import img3 from '../assets/img/rewards/img3.png';
import img11 from '../assets/img/rewards/1.png';
import img111 from '../assets/img/rewards/1-1.png';
import img112 from '../assets/img/rewards/1-2.png';
import img21 from '../assets/img/rewards/2.png';
import img211 from '../assets/img/rewards/2-1.png';
import img212 from '../assets/img/rewards/2-2.png';
import img213 from '../assets/img/rewards/2-3.png';
import img31 from '../assets/img/rewards/3.png';
import img311 from '../assets/img/rewards/3-1.png';
import { useTranslation } from '../components/common/LanguageProvider';
function Rewards() {
    const { t } = useTranslation();
   
	const handleShare = (platform) =>{
		if(platform=="linkedin"){
			window.open("https://www.linkedin.com/shareArticle?mini=true&url=https://recorditor.com&title=Screen Recorder: Record, Save, Transcribe and Share Instantly&summary=recorditor.com&source=Recorditor", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
			}
		
			if(platform=="twitter"){
			window.open("https://twitter.com/share?url=https://recorditor.com", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
			}
		
			if(platform=="facebook"){
			window.open("https://www.facebook.com/sharer/sharer.php?u=https://recorditor.com", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
			}
		
		
			if(platform=="playstore"){
			window.open("https://play.google.com/store/apps/details?id=com.transkriptor.app", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
			}
		
		
			if(platform=="appstore"){
			window.open("https://apps.apple.com/us/app/transcribe-speech-to-text/id1610386353", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
			}
		
		
			if(platform=="chrome"){
			window.open("https://chromewebstore.google.com/detail/recorditor-taray%C4%B1c%C4%B1n%C4%B1z%C4%B1-k/kiahljnhnjobpmjlfgkmpfakhjecflmm", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
			}
		
		
			if(platform=="trustpilot"){
			window.open("https://www.trustpilot.com/evaluate/recorditor.com", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
			}
		
		
			if(platform=="capterra"){
			window.open("https://reviews.capterra.com/new/251933", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
			}
		
			if(platform=="g2"){
			window.open("https://www.g2.com/products/recorditor/take_survey?source_type=user_profile", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
			}
		
		
			return;
	}
    return (
		<div className='row justify-content-center'>
		<div class="col-12">
	<div class="card card-padding card-margin">
		<div class="row gx-lg-5 align-items-center">
			<div class="col-lg-7 text-center">
				<h2 class="medium-title mb-3">{t("Invite Friends and Earn AI Credits")}</h2>
				<p class="dark-color">{t("Share Picturator on social media or comment on app stores and get free AI Credits!")}</p>
			</div>
			<div class="col-lg-5 text-center">
				<img src={img1} class="img-fluid" alt="img"/>
			</div>
		</div>
	</div>
	<div class="card card-padding card-margin">
		<div class="row gx-lg-5 justify-content-center mb-4">
			<div class="col-xxl-10 text-center">
				<h2 class="medium-title mb-3">{t("How To Earn Free AI Credits?")}</h2>
				<p class="dark-color">{t("You can easily earn free AI Credits by sharing Picturator on social media or leaving comments on the app stores. You can use your free AI Credits on both Transkriptor, Eskritor and Speaktor. Only your first post on each platform counts. Just follow the two easy steps below to earn free AI Credits now! Note: Please leave review in your native language.")}</p>
			</div>
		</div>
		<div class="row gx-lg-5 align-items-center mb-4">
			<div class="col-lg-5 text-center order-lg-1 order-2">
				<img src={img2} class="img-fluid" alt="img"/>
			</div>
			<div class="col-lg-7 order-lg-2 order-1">
				<h2 class="mini-title mb-4">{t("Step 1: Post Your Review On Any Of The Following Sites")}</h2>
				<p class="dark-color">{t("Earn 90 AI Credits for each platform you share:")}</p>
				<div class="img-list mb-2">
					<span><a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="LinkedIn" onClick={()=>handleShare("linkedin")}><img src={img11} class="img-fluid" alt="img"/></a></span>
					<span><a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Twitter"onClick={()=>handleShare("twitter")}><img src={img111} class="img-fluid" alt="img"/></a></span>
					<span><a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Facebook"onClick={()=>handleShare("facebook")}><img src={img112} class="img-fluid" alt="img"/></a></span>
				</div>
				<p class="dark-color">{t("Earn 120 AI Credits if you install Picturator and leave a review:")}</p>
				<div class="img-list mb-2">
					<span><a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Google Play" onClick={()=>handleShare("playstore")}><img src={img21} class="img-fluid" alt="img"/></a></span>
					<span><a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="App store"onClick={()=>handleShare("appstore")}><img src={img211} class="img-fluid" alt="img"/></a></span>
					<span><a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Chrome store"onClick={()=>handleShare("chrome")}><img src={img212} class="img-fluid" alt="img"/></a></span>
					<span><a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Trustpilot"onClick={()=>handleShare("trustpilot")}><img src={img213} class="img-fluid" alt="img"/></a></span>
				</div>
				<p class="dark-color">{t("Earn 180 AI Credits for each review platform:")}</p>
				<div class="img-list mb-2">
					<span><a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Capterra" onClick={()=>handleShare("capterra")}><img src={img31} class="img-fluid" alt="img"/></a></span>
					<span><a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom" title="G2" onClick={()=>handleShare("g2")}><img src={img311} class="img-fluid" alt="img"/></a></span>
				</div>
			</div>
		</div>
		<div class="row gx-lg-5 align-items-center">
			<div class="col-lg-7">
				<h2 class="mini-title mb-4">{t("Step 2: Send Screenshot of your Review")}</h2>
				<p class="dark-color">{t("If the postings are done, now it's time to verify. Have your posts and comments become publicly visible? If your answer is yes, all you have to do is upload screenshots and links. Please open the message box on right bottom corner and click on Reward Request to upload your screenshot. We will review it as soon as possible and upload your free AI Credits to your account. Verification can take up to 3 business days. We will notify you via e-mail when the verification is complete.")}</p>
			</div>
			<div class="col-lg-5 text-center">
				<img src={img3} class="img-fluid" alt="img"/>
			</div>
		</div>
	</div>
</div>
		</div>

           
    );
}

export default Rewards;