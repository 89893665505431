import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '../../components/common/LanguageProvider';



function FileGallery({
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const filesLength = 12;
    const [currentPage, setCurrentPage] = useState(1);
    const filesPerPage = 3;
    const [filesToShow, setFilesToShow] = useState([]);
    var startIndex = 0;
    var endIndex = 0;
    const [offset, setOffset] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalFile, setModalFile] = useState(null);
    const [styles, setStyles] = useState([
        {
            "url": "https://cdn.impossibleimages.ai/wp-content/uploads/2023/08/24201431/18oD18BTb61TBqrCWRdbZiDN0Kr7DTci3k0tZox33a85wlrTrc-600x600.jpg",
            "prompt": "A futuristic cityscape with flying cars and towering skyscrapers, illuminated by neon lights under a twilight sky"
        },
        {
            "url": "https://impossibleimages.ai/wp-content/uploads/fSfjR1k6QfGSkuv0FJQL0wrskc1uDgOHnSAtXrKOniWRgrGCe0-600x600.jpg",
            "prompt": "An enchanted forest filled with mythical creatures and ancient trees glowing with magical lights"
        },
        {
            "url": "https://cdn.impossibleimages.ai/wp-content/uploads/2023/08/01113417/n4QjRsDZeZWz4wjmsL306m9zB1ea33uHNNXHFM8Mow09EmB06k-600x848.jpg",
            "prompt": "A serene lakeside view at sunset, with a small wooden dock and a canoe, surrounded by mountains"
        },
        {
            "url": "https://impossibleimages.ai/wp-content/uploads/xLgrUHGx9ThOjLD7XwgQogUulhCzSjJhi9L7Z4gH9BJ8Ds3LAV-600x343.jpg",
            "prompt": "A vast desert landscape under a star-filled night sky, featuring a lone traveler with a camel"
        },
        {
            "url": "https://impossibleimages.ai/wp-content/uploads/u9kWVmv0xEllWSfCHkTBSHz1LnzPsnCcYEDTKOwsBwP9sVQhvu-600x796.jpg",
            "prompt": "A whimsical garden path leading to a hidden cottage, overflowing with flowers and fairy lights"
        },
        {
            "url": "https://cdn.impossibleimages.ai/wp-content/uploads/2023/08/04161545/Z6Nt7L8cQ1NAlTKgjUpZnu9hMup2KcG4vlESf04P9KEleJGB1C-600x452.jpg",
            "prompt": "A bustling medieval marketplace scene, filled with vendors, artisans, and townsfolk in period attire"
        },
        {
            "url": "https://cdn.impossibleimages.ai/wp-content/uploads/2024/02/26082658/wcoSg7QvGCjHHG54t0M3qLPtdoCHcZnNdqLZ9vdoqNwh2LKCIs-600x848.jpg",
            "prompt": "A mysterious ancient library hidden deep underground, with shelves of old books and magical artifacts"
        },
        {
            "url": "https://cdn.impossibleimages.ai/wp-content/uploads/2023/08/31195309/AhTLMSnmQBMbtyWmaUOqcso3gosJ9UWyRsQ1PGwJxUPabcmonP-600x600.jpg",
            "prompt": "An ice-covered landscape under the aurora borealis, with a cozy igloo and sled dogs resting nearby"
        },
        {
            "url": "https://cdn.impossibleimages.ai/wp-content/uploads/2023/05/04143929/IMdU5q8oe1apzSm9FLq4loc9EucwISo2jtPSqfaEw3RRiqs7IK-600x750.jpg",
            "prompt": "A vibrant coral reef teeming with colorful fish and marine life, illuminated by sunbeams piercing through the water"
        }
    ]);

    const [scrollIntervalId, setScrollIntervalId] = useState(null);
    const styleSelectorRef = useRef(null);

    const startScrolling = (direction) => {
        const scrollAmount = direction === 'left' ? -100 : 100;
        const intervalId = setInterval(() => {
            if (styleSelectorRef.current) {
                styleSelectorRef.current.scrollBy(scrollAmount, 0);
            }
        }, 80);
        setScrollIntervalId(intervalId);
    };

    const stopScrolling = () => {
        if (scrollIntervalId) {
            clearInterval(scrollIntervalId);
            setScrollIntervalId(null);
        }
    };

    useEffect(() => {
        // Calculate the indexes of files to be displayed on the current page
        startIndex = (currentPage - 1) * filesPerPage;
        endIndex = startIndex + filesPerPage;

        // Update the files to show based on the new indexes
        setFilesToShow(styles.slice(startIndex, endIndex));

    }, [styles, currentPage]);



    return (
        <>

            <Modal centered
                show={showModal}
                onHide={() => setShowModal(false)}
                size='xl'
            >
                <Modal.Body >
                    <h2 class="mini-title text-center mb-4">Picturator</h2>
                    <img class='d-flex justify-content-center align-items-center mx-auto mb-4'
                        style={
                            {

                                maxWidth: '100%',
                                maxHeight: '80vh'
                            }
                        }
                        src={modalFile?.url} />

                    <p class="ft-18-title mt-3 text-center">Prompt: {modalFile?.prompt}</p>

                </Modal.Body>
            </Modal>


            <label class="ft-18-title mb-3">{t("Files")}</label>

            <div class="styles-container">
                <button className="scroll-button left" onMouseDown={() => startScrolling('left')} onMouseUp={stopScrolling} onMouseLeave={stopScrolling} onTouchStart={() => startScrolling('left')} onTouchEnd={stopScrolling}>
                    <span className="fa fa-angle-left"></span>
                </button>    <div className="style-selector" ref={styleSelectorRef}>
                    {styles.map((style) => (
                        <div className="file-thumbnail-container" key={style.title} onClick={() => {
                            setShowModal(true)
                            setModalFile(style)
                        }}>
                            <img src={style.url} alt={style.title} className='file-thumbnail' />
                            <div className="file-thumbnail-overlay">
                                {style.prompt}
                            </div>
                            <div className="file-thumbnail-download-icon">
                                <i className="fa fa-download"></i>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="scroll-button right" onMouseDown={() => startScrolling('right')} onMouseUp={stopScrolling} onMouseLeave={stopScrolling} onTouchStart={() => startScrolling('right')} onTouchEnd={stopScrolling}>
                    <span className="fa fa-angle-right"></span>
                </button></div>



        </>
    );
}

export default FileGallery;

