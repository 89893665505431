import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from '../../components/common/LanguageProvider';


function PromptGenerator({
}) {
    const { t } = useTranslation();
    const [generatingPrompt, setGeneratingPrompt] = useState(false);
    const [prompt, setPrompt] = useState('');
    const navigate = useNavigate();
    const [showSubmit, setShowSubmit] = useState(true);

    const generate_prompt = async () => {
        setGeneratingPrompt(true);
        try {
            const response = await fetch("https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/eskritorcore/generateprompt?topic=" + prompt + "&app=eskritor&platform=web");
            if (response.ok) {
                const data = await response.json();
                setPrompt(data.prompt, false)
                setShowSubmit(true);
            }
        } catch (error) {
            console.error("Failed to generate prompt: ", error);
        } finally {
            setGeneratingPrompt(false);
        }
    }




    return (
        <>
            <div class="form-group">
                <label class="ft-18-title mb-2">{t("Write your prompt")}</label>
                {generatingPrompt ?
                    <div className="form-control round-input text-center" style={{ backgroundColor: 'transparent', height: '116px' }}>
                        <div class="loading-threedots">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                        </div>

                    </div> :
                    <textarea
                        value={prompt} onChange={(e) => setPrompt(e.target.value)} rows="4" class="form-control round-input" placeholder={t("What do you want to picturate?")}></textarea>
                }
            </div>
            <div class="text-center">
                <button class="btn theme-btn theme-border-btn min-btn-width mt-3 me-3" disabled={prompt === '' || generatingPrompt} onClick={(e) => {
                    generate_prompt()
                }}>
                    <span className="me-2" style={{ backgroundColor: "transparent" }}>
                        <svg viewBox="0 0 24 24" className='command-svg' width="16px" height="16px">
                            <g>
                                <rect fill="none" height="16px" width="16px" x="0"></rect>
                            </g>
                            <g>
                                <g>
                                    <path fill="currentColor" d="M20.45,6l0.49-1.06L22,4.45c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49L20.45,2c-0.18-0.39-0.73-0.39-0.91,0l-0.49,1.06 L18,3.55c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L19.55,6C19.72,6.39,20.28,6.39,20.45,6z"></path><path fill="currentColor" d="M8.95,6l0.49-1.06l1.06-0.49c0.39-0.18,0.39-0.73,0-0.91L9.44,3.06L8.95,2C8.78,1.61,8.22,1.61,8.05,2L7.56,3.06L6.5,3.55 c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L8.05,6C8.22,6.39,8.78,6.39,8.95,6z"></path><path fill="currentColor" d="M19.55,13.5l-0.49,1.06L18,15.05c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49l0.49,1.06c0.18,0.39,0.73,0.39,0.91,0l0.49-1.06 L22,15.95c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49l-0.49-1.06C20.28,13.11,19.72,13.11,19.55,13.5z"></path><path fill="currentColor" d="M17.71,9.12l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0L2.29,17.46c-0.39,0.39-0.39,1.02,0,1.41l2.83,2.83 c0.39,0.39,1.02,0.39,1.41,0l11.17-11.17C18.1,10.15,18.1,9.51,17.71,9.12z M14.21,11.21l-1.41-1.41l1.38-1.38l1.41,1.41 L14.21,11.21z"></path>
                                </g>
                            </g>
                        </svg>
                    </span>
                    {t("Improve")}</button>
                <button style={{ display: showSubmit ? '' : 'none' }} class="btn theme-btn  min-btn-width mt-3" disabled={prompt === '' || generatingPrompt} onClick={(e) => navigate("/editor", { state: { prompt: prompt } })
                }>{t("Submit")}</button>
            </div>
        </>

    );
}

export default PromptGenerator;